import type { NextPageWithLayout } from '@/model/types';

import Dashboard from '@/layouts/Dashboard';
import Module from '@/routes/Home';

/**
 * @function Page
 * @param props
 */
const Page: NextPageWithLayout = props => {
  return <Module {...props} />;
};

Page.getLayout = page => {
  return <Dashboard>{page}</Dashboard>;
};

export default Page;
