import { css } from '@emotion/css';

export const containerCx = css`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const paperCx = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
