import type { NextModules } from '@/model/types';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { containerCx, paperCx } from './styles';

/**
 * @function Home
 */
const Home: NextModules = () => {
  return (
    <Container className={containerCx} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={paperCx}>Hello World</Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
